import { useContext, useState } from "react";
import { AppContext } from "../State/Context";
import useAsyncEffect from "use-async-effect";
import { askAPI, RequestMethod, showSystemMessage } from "../utils/api";
import { ActionType } from "../State/State";
import { getTimeAgo } from "../utils/misc";

export interface Comment {
  id: string;
  author: string;
  authorName: string;
  hexCreator: string;
  x: number;
  y: number;
  text: string;
  date: string;
}

interface BasicCommentsProps {
  x: number;
  y: number;
}

export const Comments: React.FC<BasicCommentsProps> = ({ x, y }) => {
  const { state, dispatch } = useContext(AppContext);
  const player = state.player;

  const [comments, setComments] = useState<Comment[]>([]);
  const [newCommentText, setNewCommentText] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);

  const fetchComments = async () => {
    const commentsFromAPI = await askAPI<Comment[]>(
      "/api/comments/all",
      RequestMethod.GET,
      {
        x,
        y,
      }
    );

    if (commentsFromAPI) {
      setComments(commentsFromAPI.reverse());
    }
  };

  useAsyncEffect(fetchComments, []);

  const handleSendComment = async () => {
    if (newCommentText.length < 4) return;

    try {
      await askAPI("/api/comments/new", RequestMethod.POST, {
        x,
        y,
        text: newCommentText,
        isGlobal,
      });

      setNewCommentText("");
      setIsGlobal(false);

      await fetchComments();
    } catch (error) {
      showSystemMessage({
        text: "Failed to send comment",
        systemMessage: "error",
      });
    }
  };

  const clickOnPlayer = (uid: string) => {
    dispatch({ type: ActionType.SELECT_PLAYER, uid });
    dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
  };

  return (
    <div>
      {player && (
        <div className="mb-5">
          Add comment:
          <div className="pt-2 pb-2 pr-5">
            <textarea
              className="block w-full "
              value={newCommentText}
              onChange={(e) => setNewCommentText(e.target.value.slice(0, 220))}
              rows={4}
              maxLength={220}
              style={{ resize: "vertical" }}
            />
          </div>
          {/* <label className="block mb-1">
            <input
              type="checkbox"
              checked={isGlobal}
              onChange={(e) => setIsGlobal(e.target.checked)}
            />
            <span className="pl-2 text-xs opacity-70">
              Duplicate to Global Chat
            </span>
          </label> */}
          <button onClick={handleSendComment}>Send</button>
          <span className="text-xs pl-4 opacity-70">
            {comments.length
              ? "Leaving a comment will cost you just two points"
              : "First comment's free! After that, just two points each"}
          </span>
        </div>
      )}
      <div>
        {comments.length ? "Comments:" : "No comments yet"}
        {comments.map((comment) => (
          <div className="py-4" key={comment.id}>
            <div className="">{comment.text}</div>
            <div className="text-xs pt-1">
              <span
                onClick={() => clickOnPlayer(comment.author)}
                className="cursor-pointer hover:underline"
              >
                {comment.authorName}
              </span>
              . Created {getTimeAgo(comment.date)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
