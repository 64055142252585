import { useContext } from "react";
import { AppContext } from "../State/Context";
import { MenuBtn } from "./MenuBtn";
import { ActionType } from "../State/State";
import { IoMdChatboxes } from "react-icons/io";
import { MdAutoGraph } from "react-icons/md";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoNotifications } from "react-icons/io5";

export const MenuTop = () => {
  const { state, dispatch } = useContext(AppContext);

  const player = state.player;

  const isUnreadNotifications = state.notifications.some(
    (notification) => !notification.isRead
  );

  const onMostViewedClick = () => {
    dispatch({ type: ActionType.TOGGLE_MOST_VIEWED_POPUP });
  };

  const onNotificationsClick = () => {
    dispatch({ type: ActionType.TOGGLE_NOTIFICATIONS_POPUP });
  };

  return (
    <div className="fixed top-0 left-0 right-0 p-3 z-50">
      <div className="select-none absolute top-0 left-0 right-0 p-2 bg-[antiquewhite]  flex justify-between items-center">
        <div className="text-lg pl-2 flex">
          <div
            onClick={() => window.open("https://hexagen.world", "_blank")}
            className="cursor-pointer hover:underline"
          >
            hexagen⬢world
          </div>
          <div className="text-[10px] leading-3 pl-2 hidden sm:block">
            biomes
            <br />
            alpha
          </div>
        </div>
        <div className="flex">
          {player && (
            <div
              onClick={onNotificationsClick}
              className="text-[20px] mr-3 cursor-pointer"
              title="Notifications"
            >
              {isUnreadNotifications ? (
                <div className="translate-y-[3px]">
                  <IoNotifications className="animate-blink-red" />
                </div>
              ) : (
                <IoMdNotificationsOutline />
              )}
            </div>
          )}
          <div
            onClick={() =>
              dispatch({ type: ActionType.TOGGLE_GLOBAL_CHAT_POPUP })
            }
            className="text-[20px] mr-4 cursor-pointer"
            title="Comments"
          >
            <IoMdChatboxes />
          </div>
          <div
            onClick={onMostViewedClick}
            className="text-center pr-4 cursor-pointer hover:underline text-[20px]"
            title="Ratings"
          >
            <MdAutoGraph />
          </div>
          <MenuBtn />
        </div>
      </div>
    </div>
  );
};
