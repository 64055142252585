import { createNoise2D } from "simplex-noise";
import alea from "alea";

export const getTimeAgo = (date: string) => {
  const now = new Date();
  const commentDate = new Date(date);
  const diffInSeconds = Math.floor(
    (now.getTime() - commentDate.getTime()) / 1000
  );

  if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
  if (diffInSeconds < 3600)
    return `${Math.floor(diffInSeconds / 60)} minutes ago`;
  if (diffInSeconds < 86400)
    return `${Math.floor(diffInSeconds / 3600)} hours ago`;
  if (diffInSeconds < 2592000)
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  if (diffInSeconds < 31536000)
    return `${Math.floor(diffInSeconds / 2592000)} months ago`;
  return `${Math.floor(diffInSeconds / 31536000)} years ago`;
};

// DUPLICATE IN THE BACKEND
const prng = alea("lilleküla jaam");
const noise2D = createNoise2D(prng);
export const getAltitude = (x: number, y: number) => {
  // Get noise value between -1 and 1
  const noiseValue = noise2D(x / 20, y / 20);

  // Return altitude based on noise thresholds
  if (noiseValue < -0.8) return 0;
  if (noiseValue < -0.5) return 1;
  if (noiseValue < 0) return 2;
  if (noiseValue < 0.6) return 3;
  if (noiseValue < 0.9) return 4;
  return 5;
};

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
