import { useContext, useState } from "react";
import { ActionType } from "../State/State";
import { AppContext } from "../State/Context";
import { BasicPopup } from "./BasicPopup";
import { RequestMethod, askAPI } from "../utils/api";
import { Hexagen } from "../types";
import { Moon } from "../Misc/Moon";
import discord from "../Menu/discord.png";

const maxPromptLength = 100;

export const HexGeneratePopup: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [prompt, setPrompt] = useState<string>("");
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const handlePromptChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPrompt(event.target.value.slice(0, maxPromptLength));
  };

  const generateHexagen = async () => {
    if (isRequesting) return;

    setIsRequesting(true);

    if (state.selectedHexCoord) {
      const response = await askAPI<Hexagen>(
        "/api/hexagen/generate",
        RequestMethod.POST,
        {
          x: state.selectedHexCoord.x,
          y: state.selectedHexCoord.y,
          prompt,
        }
      );

      if (!response) {
        closePopup();
        return;
      }

      setIsRequesting(false);

      dispatch({
        type: ActionType.ADD_GENERATING_NOW_HEX,
        x: state.selectedHexCoord?.x,
        y: state.selectedHexCoord?.y,
      });
    }
    closePopup();
  };

  const closePopup = () => {
    setIsRequesting(false);
    dispatch({ type: ActionType.TOGGLE_HEX_GENERATE_POPUP });
  };

  return state.isHexGeneratePopupOpen ? (
    <BasicPopup onClose={closePopup}>
      <div>
        <div className="font-bold text-lg mb-3">
          Сlaim this hexagon.{" "}
          <span className="text-sm font-normal opacity-50">
            x:{state.selectedHexCoord?.x} y:
            {state.selectedHexCoord?.y}
          </span>
        </div>
        <div>
          To claim a hexagon, create a prompt for generating an object within
          it.
          <ul className="list-disc pl-4">
            <li>
              The creativity of your prompt will be evaluated by AI, awarding
              you creative points.
            </li>
          </ul>
        </div>
        <div className="mt-3 mb-2 font-bold">Generate hexagon:</div>
        <div>
          <textarea
            id="newName"
            value={prompt}
            onChange={handlePromptChange}
            className="w-full resize-none p-1 px-2 focus:outline-none"
          ></textarea>
          <div className="text-xs mb-2">
            {maxPromptLength - prompt.length} letters left
          </div>
          {state.player?.points || 0 >= 100 ? (
            isRequesting ? (
              <Moon />
            ) : (
              <button onClick={generateHexagen}>Generate</button>
              // <div className="text-red-800">
              //   Sorry! The servers of our Stable Diffusion API provider
              //   (modelslab.com) are down 😩 Please try again in an hour.
              // </div>
            )
          ) : (
            <div>
              <div className="text-red-800 mt-4">
                You are out of points for generating images 😟
              </div>

              <div className="mt-2">
                Check out our{" "}
                <a
                  href="https://discord.gg/yqkuuXnt2N"
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer"
                >
                  <img
                    src={discord}
                    alt=""
                    width={20}
                    className="inline-block mr-1 mt-[-3px]"
                  />
                  Discord
                </a>{" "}
                for a redeem code to get your first points.
              </div>
            </div>
          )}
        </div>
      </div>
    </BasicPopup>
  ) : null;
};
