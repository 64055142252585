import { useContext, useState, useEffect } from "react";
import { ActionType } from "../State/State";
import { AppContext } from "../State/Context";
import discord from "./discord.png";
import { PlayBtn } from "./PlayBtn";

export const MenuBottom = () => {
  const { state, dispatch } = useContext(AppContext);
  const [animatedText, setAnimatedText] = useState("Join us on");

  const clickOnPlayer = async () => {
    const uid = state.player?.uid;
    if (uid) {
      dispatch({ type: ActionType.SELECT_PLAYER, uid });
      dispatch({ type: ActionType.TOGGLE_PLAYER_INFO_POPUP });
    }
  };

  useEffect(() => {
    const frames = [
      ">oin us on",
      ">>in us on",
      ">>>n us on",
      "J>>> us on",
      "Jo>>>us on",
      "Joi>>>s on",
      "Join>>> on",
      "Join >>>on",
      "Join u>>>n",
      "Join us>>>",
      "Join us >>",
      "Join us o>",
      "Join us on",
    ];
    let currentFrame = 0;

    const intervalId = setInterval(() => {
      setAnimatedText(frames[currentFrame]);
      currentFrame = (currentFrame + 1) % frames.length;
    }, 250);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="fixed bottom-0 left-0 right-0 p-3">
      <div className="text-sm select-none absolute bottom-0 left-0 right-0 p-2 bg-[antiquewhite]  flex justify-between items-center">
        {state.player ? (
          <div>
            <span
              onClick={clickOnPlayer}
              className="font-bold underline cursor-pointer"
            >
              {state.player.name}
            </span>
            <span className="no-underline text-xs ml-2 mr-2">
              ({state.player.points}|<b>{state.player.regenerationPoints}</b>)
            </span>
            <PlayBtn />
          </div>
        ) : (
          <div></div>
        )}
        <div className="text-xs">
          {animatedText}{" "}
          <a
            href="https://discord.gg/yqkuuXnt2N"
            target="_blank"
            rel="noreferrer"
            className="text-xs mr-3 cursor-pointer select-none"
          >
            <img
              src={discord}
              alt=""
              width={20}
              className="inline-block mr-1 mt-[-3px]"
            />
            discord
          </a>
        </div>
      </div>
    </div>
  );
};
