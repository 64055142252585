import { useContext } from "react";
import { AppContext } from "../State/Context";
import { BasicPopup } from "./BasicPopup";
import { ActionType } from "../State/State";

export const ScamAlertPopup: React.FC = () => {
  const { dispatch } = useContext(AppContext);

  const handleClose = () => {
    dispatch({ type: ActionType.TOGGLE_SCAM_ALERT_POPUP });
    localStorage.setItem("isScamAlerWasClosed", "true");
  };

  return (
    <BasicPopup onClose={handleClose}>
      <div className="text-center">
        <div className="text-xl font-bold mb-8">‼️‼️‼️ WARNING ‼️‼️‼️</div>
        <div className="mb-4">
          Attention! This project does NOT <br />
          involve ANY crypto coins or NFTs!
        </div>
        <div className="mb-8">
          Any projects claiming affiliation with <br />
          Hexagen.World are scams!
        </div>
        <div className="font-bold mb-4">
          ©️ All rights to the game and its concept belong to the developer
          company, Taevavõrk OÜ 🇪🇪 Estonia.
        </div>
      </div>
    </BasicPopup>
  );
};
