/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useContext, useState } from "react";
import { ActionType } from "../State/State";
import { AppContext } from "../State/Context";
import { rootURL } from "../utils/settings";
import { BasicPopup } from "./BasicPopup";

export const LoginPopup: React.FC = () => {
  const { dispatch, state } = useContext(AppContext);
  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_LOGIN_POPUP });
  };

  const isLocalStorageTOSAccepted = localStorage.getItem("tos") === "true";
  const [isTOSAccepted, setIsTOSAccepted] = useState<boolean>(
    isLocalStorageTOSAccepted
  );
  const [isCookiesAccepted, setIsCookiesAccepted] = useState<boolean>(
    isLocalStorageTOSAccepted
  );

  const onTOSChange = () => {
    setIsTOSAccepted(!isTOSAccepted);
  };

  const onCookiesChange = () => {
    setIsCookiesAccepted(!isCookiesAccepted);
  };

  const onLogin = () => {
    if (isTOSAccepted && isCookiesAccepted) {
      localStorage.setItem("tos", "true");
      window.location.href = rootURL + "/api/auth/google/";
    }
  };

  return state.isLoginPopupOpen ? (
    <BasicPopup onClose={closePopup}>
      <div className="pb-1">
        <div className="font-bold mb-4">⚡️ Please login to interact ⚡️</div>
        <div className="font-bold text-lg">
          Welcome to the AI-powered browser-based world!
        </div>
        <div className="mb-6 font-bold">Powered by Google Gemini API</div>
        <div className="mb-3">
          This is a game where every player can generate their own piece of the
          world using Artificial Intelligence.
        </div>
        <div className="mb-3">
          <span className=" font-bold">Rules are simple:</span>
          <ol type="1" className="my-3">
            <li>1. Drag to the edge of the world</li>
            <li>2. Find an empty hexagon</li>
            <li>3. Write a prompt</li>
            <li>
              4. Your personal hexagon will be generated and rewarded based on
              the creativity of your prompt!
            </li>
          </ol>
          <span className="text-xs">
            You will receive a bonus equal to the hexagons creativity score for
            each view by a registered player.
          </span>
        </div>

        <div className="text-xs mt-5 mb-5 flex">
          <div className="w-8">
            <input
              onChange={onTOSChange}
              type="checkbox"
              checked={isTOSAccepted}
              className="mr-2 h-5 w-5"
            />
          </div>
          <div className="">
            I have read and agree to the 
            <a href="/tos.txt" target="_blank">
              Terms of Service
            </a>
            .
          </div>
        </div>
        <div className="text-xs flex">
          <div className="w-8">
            <input
              onChange={onCookiesChange}
              type="checkbox"
              className="mr-2 h-5 w-5"
              checked={isCookiesAccepted}
            />
          </div>
          <div className="ml-2">
            I consent to the use of cookies on this website as described in the 
            <a href="/cookies.txt" target="_blank">
              Cookie Policy
            </a>
            .
          </div>
        </div>
        <div
          className={
            "mt-6 opacity-40" +
            (isTOSAccepted && isCookiesAccepted ? "disabled" : "")
          }
        >
          <a
            onClick={(e) => {
              e.preventDefault();
              if (window.self !== window.top) {
                window.open("https://hexagen.world", "_blank");
              } else {
                onLogin();
              }
            }}
            href="#"
            className={
              "button py-1 px-2 pointer-events-none " +
              (isTOSAccepted && isCookiesAccepted && "pointer-events-auto")
            }
          >
            Auth with Google Account
          </a>
        </div>
      </div>
    </BasicPopup>
  ) : null;
};
