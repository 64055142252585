import { BasicPopup } from "./BasicPopup";
import { ActionType } from "../State/State";
import { useContext } from "react";
import { AppContext } from "../State/Context";
import { img } from "../utils/settings";
import { hexWidth, hexHeight } from "../World/World";
import { RequestMethod } from "../utils/api";
import { askAPI } from "../utils/api";

// Separate type for notification
type Notification = {
  createdAt: string;
  isRead: boolean;
  message: string;
  coordinates?: {
    x: number;
    y: number;
  };
};

// Separate component for notification item
const NotificationItem = ({
  notification,
  onHexClick,
}: {
  notification: Notification;
  onHexClick: (x: number, y: number) => void;
}) => (
  <div
    className={`flex mb-4 items-center cursor-pointer ${
      notification.isRead ? "opacity-70 hover:opacity-100" : ""
    }`}
    onClick={() =>
      onHexClick(notification.coordinates!.x, notification.coordinates!.y)
    }
  >
    {notification.coordinates && (
      <img
        src={img(
          `x${notification.coordinates.x}y${notification.coordinates.y}.png`
        )}
        alt="hexagen"
        width={100}
        height={100}
        className="mr-4"
      />
    )}
    <div className="mr-4 whitespace-pre-line">{notification.message}</div>
  </div>
);

export const NotificationsPopup = () => {
  const { state, dispatch } = useContext(AppContext);
  const notifications = state.notifications;

  const closePopup = async () => {
    dispatch({ type: ActionType.TOGGLE_NOTIFICATIONS_POPUP });
    await askAPI("/api/notifications/mark-all-as-read", RequestMethod.POST);
    dispatch({
      type: ActionType.UPDATE_NOTIFICATIONS,
      notifications: notifications.map((notification) => ({
        ...notification,
        isRead: true,
      })),
    });
  };

  const handleHexClick = (x: number, y: number) => {
    dispatch({ type: ActionType.SELECT_HEX, x, y });
    dispatch({ type: ActionType.TOGGLE_HEX_INFO_POPUP });
    dispatch({ type: ActionType.TOGGLE_NOTIFICATIONS_POPUP });
    dispatch({
      type: ActionType.CHANGE_WORLD_COORDINATES,
      x: -x * hexWidth * state.worldScale,
      y: -(y - 2) * hexHeight * state.worldScale,
    });
  };

  return (
    <BasicPopup onClose={closePopup}>
      <div>
        <div className="text-lg font-bold mb-4">Notifications</div>
        {notifications.length === 0 ? (
          <div>No notifications</div>
        ) : (
          notifications.map((notification, i) => (
            <NotificationItem
              key={i}
              notification={notification}
              onHexClick={handleHexClick}
            />
          ))
        )}
      </div>
    </BasicPopup>
  );
};
