import { useContext } from "react";
import useAsyncEffect from "use-async-effect";
import "./App.scss";
import { MenuBottom } from "./Menu/MenuBottom";
import { MenuTop } from "./Menu/MenuTop";
import { HexGeneratePopup } from "./Popups/HexGeneratePopup";
import { HexInfoPopup } from "./Popups/HexInfoPopup";
import { LoginPopup } from "./Popups/LoginPopup";
import { PlayerProfilePopup } from "./Popups/PlayerProfilePopup";
import { ActionType } from "./State/State";
import { SystemMessage } from "./SystemMessage/SystemMessage";
import { World } from "./World/World";
import { RequestMethod, askAPI } from "./utils/api";
import { Player } from "./types";
import { PlayerInfoPopup } from "./Popups/PlayerInfoPopup";
import { MenuPopup } from "./Menu/MenuPopup";
import { AppContext } from "./State/Context";
import { AboutGamePopup } from "./Popups/AboutGamePopup";
import { ZoomController } from "./Zoom/Zoom";
import { GeneratedPopupAward } from "./Popups/GeneratedAwardPopup";
import { RatingsPopup } from "./Popups/RatingsPopup";
import { MiniMap } from "./Map/Map";
import { GlobalChatPopup } from "./Popups/GlobalChatPopup";
import { RedeemPopup } from "./Popups/RedeemPopup";
import { ScamAlertPopup } from "./Popups/ScamAlertPopup";
import { INotification } from "./types";
import { NotificationsPopup } from "./Popups/NotificationsPopup";

function App() {
  const { state, dispatch } = useContext(AppContext);

  useAsyncEffect(() => {
    const fetchPlayer = async () => {
      const player = await askAPI<Player>("/api/player", RequestMethod.GET);
      dispatch({ type: ActionType.UPDATE_PLAYER, player });
    };

    const fetchNotifications = async () => {
      const notifications = await askAPI<INotification[]>(
        "/api/notifications",
        RequestMethod.GET
      );
      if (notifications) {
        dispatch({ type: ActionType.UPDATE_NOTIFICATIONS, notifications });
      }
    };

    const updateData = async () => {
      try {
        await Promise.all([fetchPlayer(), fetchNotifications()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const intervalId = setInterval(updateData, 10000);
    updateData();

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App p-2">
      <MenuTop />
      <World />
      <ZoomController />
      <MiniMap />
      <MenuBottom />
      {state.isMostViewedPopupOpen && <RatingsPopup />}
      {state.isHexInfoPopupOpen && <HexInfoPopup />}
      <PlayerProfilePopup />
      <LoginPopup />
      <HexGeneratePopup />
      {state.isPlayerInfoPopupOpen && <PlayerInfoPopup />}
      {state.isAboutGamePopupOpen && <AboutGamePopup />}
      <GeneratedPopupAward />
      {state.isRedeemPopupOpen && <RedeemPopup />}
      <SystemMessage />
      {state.isMenuPopupOpen && <MenuPopup />}
      {state.isGlobalChatPopupOpen && <GlobalChatPopup />}
      {state.isScamAlertPopupOpen && <ScamAlertPopup />}
      {state.isNotificationsPopupOpen && <NotificationsPopup />}
    </div>
  );
}

export default App;
