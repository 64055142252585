import { useContext } from "react";
import { AppContext } from "../State/Context";
import { BasicPopup } from "./BasicPopup";
import { ActionType } from "../State/State";

export const AboutGamePopup: React.FC = () => {
  const { dispatch } = useContext(AppContext);
  const closePopup = () => {
    dispatch({ type: ActionType.TOGGLE_ABOUT_GAME_POPUP });
  };
  return (
    <BasicPopup onClose={closePopup}>
      <div className="">
        <h1 className="text-2xl font-bold pb-4">About Game and Rules</h1>
        <p>
          <b>Hexagen.World</b> is a collectively generated game world created by
          all players.
        </p>
        <p className="py-4 font-bold">The rules are simple: </p>
        <ul className="list-disc pl-4 mb-4">
          <li>
            1️⃣ Explore the edge of the world using the map or simply drag the
            canvas to navigate.
          </li>
          <li>2️⃣ Click on an empty hexagon at the edge to begin.</li>
          <li>
            3️⃣ Come up with a prompt and generate a piece of the world in the
            selected hexagon.
          </li>
          <li>
            4️⃣ Your hexagon will be evaluated by AI in creativity points for the
            creativity you've shown.
          </li>
        </ul>
        <div className="mb-4">
          <h2 className="font-bold mb-2">How to Earn Points for Generation</h2>

          <div className="mb-2">
            <div className="font-bold">1. Starting Balance:</div>
            <div className="ml-4">
              Every player starts with 100 points after registering.
            </div>
          </div>

          <div className="mb-2">
            <div className="font-bold">2. Passive Earning:</div>
            <ul className="ml-4 list-disc pl-4">
              <li>
                Players earn 1 point per hour if their balance is less than 1000
                points.
              </li>
              <li>You can earn up to 60 points at a time.</li>
            </ul>
          </div>

          <div className="mb-2">
            <div className="font-bold">3. Upvote Bonus:</div>
            <ul className="ml-4 list-disc pl-4">
              <li>You get 10 points for upvoting someone else's hexagon.</li>
              <li>
                The bonus applies if you've given fewer than 6 upvotes in the
                past 24 hours.
              </li>
            </ul>
          </div>

          <div className="mb-2">
            <div className="font-bold">
              4. Rewards for Your Hexagon Upvotes:
            </div>
            <ul className="ml-4 list-disc pl-4">
              <li>
                You earn a bonus equal to 50% of your hexagon's value when
                someone upvotes it.
              </li>
              <li>
                You can only receive the bonus for up to 3 upvotes per player in
                a 30-day period, but players can upvote your hexagon as many
                times as they want.
              </li>
            </ul>
          </div>

          <div className="mb-2">
            <div className="font-bold">5. Promo Codes:</div>
            <ul className="ml-4 list-disc pl-4">
              <li>You can earn extra points by redeeming promo codes.</li>
              <li>Promo codes are shared in places like our Discord.</li>
            </ul>
          </div>
        </div>
        <p>
          <b>Attention! </b>This is an early preview version, so generations
          take up to 30 seconds, some things may not work, and the functionality
          is not complete.
        </p>
        <p className="mt-4">
          ©️ This project was created by <b>Mikhail Dunaev</b>, and all
          intellectual property rights are owned by <b>Taevavõrk OÜ.</b>
        </p>
        <p className="mt-4">
          Follow me on{" "}
          <a
            href="https://www.linkedin.com/in/mikhail-dunaev/"
            rel="noreferrer"
            target="_blank"
          >
            LinkedIn
          </a>{" "}
          to learn about updates and other projects!
        </p>
        <p className="mt-4">Made in Estonia 🇪🇪 with 💙</p>
      </div>
    </BasicPopup>
  );
};
